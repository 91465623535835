import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { EXPERIMENT_POST_PAGE_SCROLL_TO_COMMENTS } from '@wix/communities-blog-experiments';
import { connect } from '../runtime-context';

import LikeButtonWithCount from '../like-button-with-count';
import PostSocialActions from '../post-social-actions';
import ViewCountCompact from '../view-count-compact';
import CommentCountCompact from '../../../comments/components/comment-count-compact';
import { HorizontalSeparator } from '../separator';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withExperiment from '../../hoc/with-experiment';
import { getViewCount, getCommentCount } from '../../store/post-counters/post-counters-selectors';
import { getSocialSharingProviders } from '../../selectors/app-settings-selectors';
import styles from './post-main-actions-mobile.scss';
import { CommentCountButton } from './post-main-actions-comment-count-button';

const PostMainActionsMobile = ({
  canRender,
  post,
  incrementPostLikeCount,
  forPublicUser,
  isMetadataFooterVisible,
  showViewCount,
  showCommentCount,
  showLikeCount,
  showShareButtons,
  viewCount,
  totalComments,
  enabledProviders,
  isExperimentScrollToCommentsEnabled,
  t,
}) => (
  <div className={styles.container}>
    {showShareButtons &&
      canRender('share', 'post', post, () => (
        <div className={styles.shareButtons}>
          <PostSocialActions
            postSlug={post.slug}
            path={`/${post.slug}`}
            postId={post._id}
            enabledProviders={enabledProviders}
          />
        </div>
      ))}
    <HorizontalSeparator />
    {isMetadataFooterVisible && (
      <div className={styles.flexContainer}>
        <div className={styles.stats}>
          {showViewCount && <ViewCountCompact count={viewCount} tabIndex={0} />}
          {showCommentCount && (
            <CommentCountButton
              ariaLabel={t('comment-count.button.aria-label')}
              commentCount={<CommentCountCompact count={totalComments} tabIndex={0} />}
              isExperimentScrollToCommentsEnabled={isExperimentScrollToCommentsEnabled}
            />
          )}
        </div>
        {showLikeCount && (
          <LikeButtonWithCount onClick={forPublicUser(() => incrementPostLikeCount(post._id))} entity={post} />
        )}
      </div>
    )}
  </div>
);

PostMainActionsMobile.propTypes = {
  post: PropTypes.object.isRequired,
  incrementPostLikeCount: PropTypes.func.isRequired,
  canRender: PropTypes.func,
  t: PropTypes.func,
  forPublicUser: PropTypes.func,
  isMetadataFooterVisible: PropTypes.bool,
  showViewCount: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  showShareButtons: PropTypes.bool,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  enabledProviders: PropTypes.array.isRequired,
  isExperimentScrollToCommentsEnabled: PropTypes.bool.isRequired,
};

PostMainActionsMobile.defaultProps = {
  showShareButtons: true,
};

const mapRuntimeToProps = (state, { post }, actions) => ({
  incrementPostLikeCount: actions.incrementPostLikeCount,
  viewCount: getViewCount(state, post._id),
  totalComments: getCommentCount(state, post._id),
  enabledProviders: getSocialSharingProviders(state),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withPermissions,
  withTranslate,
  withAuth,
  withFeedMetadataSettings,
  withExperiment({
    isExperimentScrollToCommentsEnabled: EXPERIMENT_POST_PAGE_SCROLL_TO_COMMENTS,
  })
)(PostMainActionsMobile);
