import React from 'react';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { ImgProvider, RENDER_IMAGE, RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY } from '@wix/communities-image-lib';
import { initI18n } from '@wix/communities-blog-client-common';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import PermissionsProvider from '../../../common/components/permissions-provider';
import Router from '../router';
import {
  getLanguage,
  isSeo,
  getIsMobile,
  isEditor,
  getIsICUEnabled,
} from '../../../common/store/basic-params/basic-params-selectors';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { FastFormProvider } from '../../../common/components/fast-form';
import { getFastFormSubmitAction } from '../../../common/components/fast-form/store/fast-form-selectors';
import '../../../rich-content/components/rich-content-editor/rich-content-editor.global.scss';
import pinterest from '../../../common/services/pinterest';
import {
  getPinterestEnabled,
  getIsCreatedWithResponsiveEditor,
} from '../../../common/selectors/app-settings-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import WindowResizeListener from '../../../common/components/window-resize-listener';
import BlogPushNotifications from '../../../common/components/blog-push-notifications';
import PostPushNotifications from '../../components/post-push-notifications';
import './app-root.scss';
import { withRootProps } from '../../../common/components/root-props/with-root-props';

class AppRoot extends React.Component {
  constructor(props) {
    super(props);
    const { language, isMobile, isPinterestEnabled } = this.props;
    pinterest.init({ language, isMobile, isPinterestEnabled });
  }

  componentWillUnmount() {
    pinterest.removeEventListener();
  }

  getI18nConfig = () => {
    const { language, translations, isICUEnabled } = this.props;

    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations, isICUEnabled);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { addResizeListener, submitAction, isSeo } = this.props;
    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener>
        <PermissionsProvider>
          <I18nextProvider i18n={i18n}>
            <ImgProvider
              renderMode={isSeo ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY}
              {...(isWebpSupported() ? { format: 'webp' } : {})}
            >
              <FastFormProvider
                submitAction={submitAction}
                // onSubmit={actions.fastFormSubmitCallback} // unused currently, when used need to tranform contentState
              >
                <Router />
                {addResizeListener && <WindowResizeListener />}
                <BlogPushNotifications />
                <PostPushNotifications />
              </FastFormProvider>
            </ImgProvider>
          </I18nextProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = (state) => {
  const isInClassicEditor = isEditor(state) && !getIsCreatedWithResponsiveEditor(state);
  return {
    language: getLanguage(state),
    translations: getTranslations(state),
    isSeo: isSeo(state),
    isMobile: getIsMobile(state),
    isPinterestEnabled: getPinterestEnabled(state),
    submitAction: getFastFormSubmitAction(state),
    addResizeListener: isInClassicEditor,
    isICUEnabled: getIsICUEnabled(state),
  };
};

export default withRootProps(withReduxStore(connect(mapRuntimeToProps)(AppRoot)));
